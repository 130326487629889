(function ($, arc) {

    $(document).ready(function () {
        arc.contentFeedback = new ContentFeedback();
    });

    function ContentFeedback(options) {
        this.dialog = null;
    }

    ContentFeedback.prototype.openInfo = function () {
        $i.dialog({
            title: $i.i18n.get('content.feedback.success.header'),
            body: '<span class="feedback-description">' + $i.i18n.get('content.feedback.success.thank-you') + '</span>',
            id: 'content-feedback-thank-you',
            modal: true,
            bgiframe: true,
            width: '40%',
            buttonKeys: {
                'button.dismiss': {
                    callback: function () {
                        this.close();
                    }
                }
            }
        });
    };

    ContentFeedback.prototype.open = function () {
        this.dialog = this.getInitialFeedbackDialog();

        $(window).on('resize.isis-dialog', _.debounce($.proxy(function () {
            if (this.dialog.box.hasClass('dialog-hide') || this.dialog.box.hasClass('ie-hide')){
                this.resize();
            }
        }.bind(arc.contentFeedback)), 400));

    };

    ContentFeedback.prototype.close = function () {
        this.dialog.close();
        $(window).off('resize.isis-dialog');
    };

    ContentFeedback.prototype.resize = function () {
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();
        if (isIe()) {
            this.dialog.box.css({top : $(window).height() - 41, left :(windowWidth - 300), width : 300});
        } else {
            var dialogWidth = this.dialog.box.width();
            var leftpad = windowWidth > dialogWidth ? (windowWidth/2) - dialogWidth : -100;
            this.dialog.box.css({left : leftpad});
        }

        if (window.matchMedia("(orientation: landscape)").matches) {
            var rotatedHeight = window.screen.width;
            var elemY = this.dialog.box[0].getBoundingClientRect().y;
            if (elemY > rotatedHeight) {
                console.log('b');
                this.dialog.box[0].style.top = (rotatedHeight - (elemY + 60)) + "px";
            }
        }

    };

    ContentFeedback.prototype.getFeedbackDialog = function () {
        var thiz = this;
        return $i.dialog({
            id: 'content-feedback',
            url: $i.contextPath + "/content/feedback/feedbackDialog",
            data: { url: encodeURI(window.location.href)},
            title: window.location.href.indexOf("/rts/") !== -1 ? $i.i18n.get('content.feedback.rts.title') :  $i.i18n.get('content.feedback.dialog.head'),
            modal: true,
            bgiframe: true,
            width: '600px',
            skeletonTemplate : '<div class="content-feedback-dialog-container">' +
                                '<div class="dialog-box">' +
                                '<div class="dialog-content">' +
                                '<div class="dialog-head">' +
                                '<h2>{{title}}</h2>' +
                                '<a class="maximise-dialog" href="#" style="display: none;">' + $i.i18n.get('content.feedback.dialog.maximise') + '</a>' +
                                '</div>' +
                                '<div class="dialog-body"></div>' +
                                '<div class="dialog-foot"></div>' +
                                '</div>' +
                                '</div>' +
                                '</div>',
            close: function() {
                $(".dialog-overlay").remove();
                $(".content-feedback-dialog-container").remove();
            },
            buttonKeys: {
                'button.cancel': { classes: 'button-secondary', callback: function() {
                    this.close();
                }},
                'content.feedback.submit': { classes: 'btn btn-info btn-submit-feedback disabled', id: 'submit-content-feedback', callback: function() {
                }
            }},
            load: function () {
                initContentFeedbackDialog();
                arc.contentFeedback.dialog.resize();
                makeUploadAccordion(thiz.dialog.$el.get(0));
            }
        });
    };

    ContentFeedback.prototype.getInitialFeedbackDialog = function () {
        var thiz = this;
        $i.dialog({
            title: $i.i18n.get('content.feedback.confirm'),
            body: '<p><strong>' + $i.i18n.get('content.feedback.confirm.dialog.important.message.statement') + '</strong></p>' +
                  '<p>' + $i.i18n.get('content.feedback.confirm.dialog.confirm.statement') + '</p>' +
                  '<p>' + $i.i18n.get('content.feedback.confirm.dialog.not.required.statement') + '</p>' +
                  '<ul><li>' + $i.i18n.get('content.feedback.confirm.dialog.not.required.scenario1') + '</li>' +
                  '<li>' + $i.i18n.get('content.feedback.confirm.dialog.not.required.scenario2') + '</li></ul>' +
                  '<p>' + $i.i18n.get('content.feedback.confirm.dialog.message.footer') + '</p>',
            id: 'rts-initial-feedback-dialog',
            modal: true,
            bgiframe: true,
            width: '600px',
            close: function() {
                $(".dialog-overlay").remove();
            },
            buttonKeys: {
                'button.cancel': { classes: 'button-secondary', callback: function() {
                    this.close();
                }},
                'content.feedback.confirm': { classes: 'btn btn-info', callback: function() {
                    this.close();
                    thiz.dialog = thiz.getFeedbackDialog();
                }}
            }
        });
    };

    function isIe() {
        var ua = window.navigator.userAgent;
        return ua.indexOf('MSIE') !== -1 || ua.indexOf("Trident") !== -1;
    }

    /**
     * Creates Accordion for given element.
     * @param {HTMLElement} elem element that you want to make an Accordion
     */
    function makeUploadAccordion (elem) {
        $(elem).find('.accordionHeader').each(function () {
            $(this).on("click", function () {
                this.classList.toggle("active");
                var $details = $(this).next();
                if ($details.is(':visible')) {
                    $details.slideUp('slow');
                } else {
                    $details.slideDown('slow');
                }
            })
        })
    }

})(jQuery, arc);

