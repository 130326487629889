/* jshint es3:true */
(function($i, $, undefined) {
    var collapseIconTemplate = '<svg class="topix-dialog-collapse-icon" data-name="topix-dialog-collapse-icon" ' +
        'xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><defs></defs><title>' + $i.i18n.get('button.collapse') +
        '</title><path class="collapse-icon-fill" d="M 8 12 L 15.85 3.85 a 0.48 0.48 0 0 0 0 -0.7 a 0.48 0.48 0 0 0 -0.7 0 L 8 10.29 L 0.85 3.15 a 0.48 0.48 0 0 0 -0.7 0 a 0.48 0.48 0 0 0 0 0.7 Z" />' +
        '</svg>';

    var expandIconTemplate = '<svg class="topix-dialog-expand-icon" data-name="topix-dialog-expand-icon" ' +
        'xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><defs></defs><title>' + $i.i18n.get('button.expand') +
        '</title><path class="expand-icon-fill" d="M 8 4 L 0.15 12.15 a 0.48 0.48 180 0 0 0 0.7 a 0.48 0.48 180 0 0 0.7 0 L 8 5.71 L 15.15 12.85 a 0.48 0.48 180 0 0 0.7 0 a 0.48 0.48 180 0 0 0 -0.7 Z"/>' +
        '</svg>';

    var collapseButtonTemplate = '<a class="dialog-collapse-button" title="'+ $i.i18n.get('button.collapse')+ '"></a>';

    var isExpanded = true;

    var defaults = {
        width: '98%',
        bodyHeight: '90vh'
    }

    /**
     * @param {CollapsibleDialogOptions} options
     * @constructor
     */
    function CollapsibleDialog(options)
    {
        this.options = $.extend({},
            defaults,
            options);

        this.dialog = $i.dialog(this.options);

        this.appendCollapseButton();
        this.dialog.box.addClass('render-out-of-screen-and-slide');

        this.dialog.overlay.remove();
        this.dialog.box.find('.dialog-foot').remove();
    }

    /**
     * Adds collapse button to the dialog header.
     */
    CollapsibleDialog.prototype.appendCollapseButton = function () {
        var thiz = this;
        thiz.dialog.box.find('.dialog-head').find('.dialog-collapse-button').remove().end().append($.mustache(collapseButtonTemplate));
        thiz.dialog.box.find('.dialog-head').find('.dialog-collapse-button').on('click', function () {
            if (isExpanded) {
                thiz.collapse();
            } else {
                thiz.expand();
            }
        });
    };

    /**
     * Collapse the dialog by hiding dialog body and moving the header to the bottom.
     */
    CollapsibleDialog.prototype.collapse = function () {
        var thiz = this;

        //Hide body element and move dialog head to the bottom of the page
        thiz.dialog.box.find('.dialog-body').hide();
        thiz.dialog.box.removeClass('render-out-of-screen-and-slide');
        thiz.dialog.box.addClass('slide-down-to-bottom');

        //Change collapse icon to expand icon
        thiz.dialog.box.find('.dialog-head').find('.dialog-collapse-button').find('.topix-dialog-collapse-icon').remove();
        thiz.dialog.box.find('.dialog-head').find('.dialog-collapse-button').find('.topix-dialog-expand-icon').remove();
        thiz.dialog.box.find('.dialog-head').find('.dialog-collapse-button').append($.mustache(expandIconTemplate));
        isExpanded = false;
    };

    /**
     * Expand the dialog by showing dialog body and moving the whole dialog to original position.
     */
    CollapsibleDialog.prototype.expand = function () {
        var thiz = this;

        //Show body element and move it to the top
        thiz.dialog.box.find('.dialog-body').show();
        thiz.dialog.box.removeClass('slide-down-to-bottom');
        thiz.dialog.box.addClass('render-out-of-screen-and-slide');

        //Change expand icon to collapse icon
        thiz.dialog.box.find('.dialog-head').find('.dialog-collapse-button').find('.topix-dialog-collapse-icon').remove();
        thiz.dialog.box.find('.dialog-head').find('.dialog-collapse-button').find('.topix-dialog-expand-icon').remove();
        thiz.dialog.box.find('.dialog-head').find('.dialog-collapse-button').append($.mustache(collapseIconTemplate));
        isExpanded = true;
    };

    /**
     * Open the dialog.
     */
    CollapsibleDialog.prototype.open = function () {
        this.expand();
        this.dialog.open();
    };

    /**
     * Close the dialog.
     */
    CollapsibleDialog.prototype.close = function () {
        this.dialog.close();
    };

    /**
     * Bind the dialog to the application namespace.
     */
    $i.collapsibleDialog = function () {
        // Plain options object.
        var options = arguments[0];

        // Single string argument: dialog selector.
        if (arguments.length === 1 && $i.isString(arguments[0])) {
            options = { dialogSelector: arguments[0] };
        }

        // String and an object: configuration name and options object.
        else if (arguments.length === 2 && $i.isString(arguments[0]) && $i.isObject(arguments[1])) {
            options = $.extend(arguments[1], { configuration: arguments[0] });
        }

        return new CollapsibleDialog(options);
    };

    /**
     * @typedef {Object} CollapsibleDialogOptions
     * @property {string} configuration Name of the preexisting options for dialog ("default", "alert", "confirm", "confirm-delete"
     * @property {boolean} autoOpen True if dialog should be auto open on init
     * @property {boolean} modal True if background overlay should be added
     * @property {string} id Id of the dialog
     * @property {string} cache True if existing dialog with id should be loaded
     * @property {string} buttonSelector Button that opens the dialog - if specified will be disabled on open and enabled on close
     * @property {boolean} closable Whether to provide a close icon and/or close the dialog box when the escape key is pressed
     * @property {string} width Width of dialog-box element
     * @property {string} height Height of dialog-box element
     * @property {string} minWidth MinWidth of dialog-box element
     * @property {string} bodyHeight Height of dialog-body element
     * @property {boolean} fullWidthMobile Whether to ignore the specified width on mobile
     * @property {string} mobileMaxScreenWidth The maximum screen width that defines a mobile
     * @property {string} url The url (using the special syntax from jQuery's load methods (see http://api.jquery.com/load/)) for loading the dialog body via AJAX
     * @property {Object} data If the url option is used data provides params while loading
     * @property {string} dialogSelector Selector for the dialog
     * @property {string} bodySelector Selector for the dialog body
     * @property {string} title Set title for the dialog
     * @property {string} body Set dialog body from the string
     * @property {Object} buttons Buttons for dialog foot
     * @property {string} titleKey Create dialog title from i18n property
     * @property {string} bodyKey Create dialog body from i18n property
     * @property {Object} buttonKeys Create dialog foot buttons with provided values
     * @property {function} load Run function on load
     * @property {function} open Run function on open
     * @property {function} close Run function on close
     * @property {function} destroy Run function on destroy
     * @property {function} confirm Run function on confirm
     * @property {function} cancel Run function on cancel
     * @property {string} skeletonTemplate Provide custom template for dialog
     */

})(isis, jQuery);
