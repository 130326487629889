(function(arc, $) {
  function NotificationCentre(options) {
    this.options = options || {};
    this.isIe8 = $.browser.msie && $.browser.versionNumber === 8;
    this.isOpen = false;
    this.isFilterable = typeof this.options.isFilterable === 'boolean' ? this.options.isFilterable : false;
    this.isFiltered = false;
    this.isFiltering = false;
    this.isToggling = false;
    this.$window = $(window);
    this.$document = $(document);
    this.centre = this.create();
    this.notifications = [];
    this.notificationsDays = {};
    this.destroyQueue = [];
    this.seenQueue = [];
    this.trigger = new NotificationTrigger(this);

    this.$document.on('click.arc-notification-centre', function(e) {
      if (this.isOpen && !$(e.target).is(this.centre.$centre) && !this.centre.$centre.find($(e.target)).length) {
        this.close();
      }
    }.bind(this));

    if (this.isFilterable) {
      this.renderFilter();
    }
  }

  function removeNotificationCountCache(notificationIds) {
      if (notificationIds && notificationIds.length) {
          sessionStorage.removeItem('notificationCount');
      }
  }

  function setNotificationCountCache(count, expiryTime) {
      sessionStorage.setItem('notificationCount', JSON.stringify({
          count: count,
          expiryTime: expiryTime
      }));
  }

  function getNotificationCountCache() {
      return JSON.parse(sessionStorage.getItem('notificationCount'));
  }

  NotificationCentre.prototype.create = function() {
    var $centre = $('<div/>').addClass('notification-centre'),
        $header = $('<div/>').addClass('notification-centre-header').appendTo($centre),
        $title = $('<h2/>').addClass('notification-centre-title').text($i.i18n.get('arc.notification-centre.title')).appendTo($header),
        // $titleLoading = $('<span/>').addClass('notification-centre-loading').hide().appendTo($title),
        $body = $('<div/>').addClass('notification-centre-body').appendTo($centre),
        $content = $('<div/>').addClass('notification-centre-content').appendTo($body),
        $loading = this.isIe8 ?
          $('<div/>').addClass('notification-centre-loading-ie8 align-center soft-double-top').append(
            $('<img/>', {src: arc.defaultContextPath + '/images/arc/ie8-spinner.gif'})
          ) :
          $('<div/>').addClass('notification-centre-loading'),
        $noNotifications = $('<div/>').addClass('notification-centre-no-notifications').append(
          $('<i/>').addClass('fa fa-frown-o'),
          $('<span/>').text(arc.i18n.get('arc.notification-centre.no-notifications.unfiltered'))
        ),
        $noNotificationsFiltered = $('<div/>').addClass('notification-centre-no-notifications').append(
          $('<i/>').addClass('fa fa-frown-o'),
          $('<span/>').text(arc.i18n.get('arc.notification-centre.no-notifications.filtered'))
        );

    return {
      $centre: $centre,
      $header: $header,
      $title: $title,
      // $titleLoading: $titleLoading,
      $body: $body,
      $content: $content,
      $loading: $loading,
      $noNotifications: $noNotifications,
      $noNotificationsFiltered: $noNotificationsFiltered
    };
  };

  NotificationCentre.prototype.toggle = function() {
    if (this.isToggling) {
      return;
    }

    if (this.isOpen) {
      this.close();
      return;
    }

    this.open();
  };

  NotificationCentre.prototype.open = function() {
    this.isToggling = true;
    // add centre to DOM
    this.centre.$centre.appendTo($('body'));
    this.centre.$content.empty();
    this.notifications = [];
    this.notificationsDays = {};

    // wait to add open class so sidebar animates inward
    setTimeout(function() {
      this.centre.$centre.addClass('notification-centre-open');
      this.isOpen = true;
      this.isToggling = false;
      if (this.centre.$filter) {
        this.centre.$filter.find('input').on('click', function(e) {
          this.toggleFilter(e);
        }.bind(this));
      }
      // update trigger counter UI as all notifications have now been seen on open

    }.bind(this), 0);

    // generate notifications - request data from server
    // no callback, true for initial run
    this.refresh(true);
    $('.notification-centre-trigger').addClass('active');
  };

  NotificationCentre.prototype.renderNotifications = function() {
    var prevNotification;

    $.each(this.notifications, function(i, notification) {
      prevNotification = i !== 0 && this.notifications[i - 1] || null;

      // if first notification or prev notification is not same date as current notification
      if (!prevNotification || prevNotification && prevNotification.date !== notification.date) {

          var notificationDate = notification.date;
          var $dayTitleWrapper = $('<div/>').addClass('notification-centre-content-title-wrapper').attr('data-date', notificationDate);
          this.centre.$content.append($dayTitleWrapper);
          var $dayClose = $('<a>').addClass('notification-day-close').text(arc.i18n.get('arc.notification-centre.clear.all'));
          $dayTitleWrapper.append($dayClose);
          $dayTitleWrapper.append($('<h3/>').addClass('notification-centre-content-title').attr('data-date', notificationDate).text(notificationDate));
          this.notificationsDays[notificationDate] = [];
          $dayClose.on('click', this.readAllForDay.bind(this, notificationDate));
      }

      this.notificationsDays[notification.date].push(notification.postId);

      this.centre.$content.append(notification.notification.$notification);
    }.bind(this));


    if (this.centre.$filter && this.centre.$filter.find('input')[0].checked) {
      this.filter(true);
    }
  };

  NotificationCentre.prototype.readAllForDay = function (day)
  {
      var notificationDay = this.notificationsDays[day];
      $.ajax({
          url: arc.defaultContextPath + '/service/notification/changeUserNotificationStateToRead',
          type: 'POST',
          cache: false,
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({'notificationIds': notificationDay})
      }).always(function() {
          this.open();
          removeNotificationCountCache(notificationDay);
      }.bind(this));
  };

  NotificationCentre.prototype.calcBody = function() {
    this.centre.$body.css('height', this.centre.$centre.outerHeight() - this.centre.$header.outerHeight(true));
  };

  NotificationCentre.prototype.close = function() {
    if (this.isRefreshing) {
      return false;
    }

    this.isToggling = true;
    this.centre.$centre.removeClass('notification-centre-open');
    this.isOpen = false;

    $('.notification-centre-trigger').removeClass('active');

    setTimeout(function() {
      this.centre.$content.empty();
      this.notifications = [];
      this.notificationsDays = {};
      this.centre.$centre.remove();
      this.isToggling = false;
      this.$window.off('resize.arc-notification-centre');
      // update trigger counter UI again incase of new ones polling in
      this.trigger.counter = 0;
      this.trigger.updateTrigger();
    }.bind(this), 300);
  };

  NotificationCentre.prototype.requestNotifications = function(done, initial) {
    $.ajax({
      url: arc.defaultContextPath + '/service/notification/getNotificationsList',
      type: 'GET',
      cache: false
    })
    .done(function(data) {
      // checks if filter functionality should be injected based on vehicleSpecific boolean
      $.each(data, function(i, notificationData) {
        // render filter in centre if data has vehicleSpecific notifications
        if (!this.isFilterable && notificationData.vehicleSpecific) {
          this.renderFilter();
          // stop looping if this matched
          return false;
        }
      }.bind(this));

      // pass data back to parent function
      if (typeof done === 'function') {
        done(data);
      }
    }.bind(this));

    return;
  };

  NotificationCentre.prototype.createNotifications = function(done) {
    var notifications = [];
    this.requestNotifications(function(notificationsData) {
      $.each(notificationsData, function(i, notificationData) {
        notifications.push(new Notification($.extend({notificationCentre: this, index: i}, notificationData)));
      }.bind(this));

      // run callback to deal with notification post-creation
      if (typeof done === 'function') {
        done(notifications);
      }
    }.bind(this), true);

    return notifications;
  };

  // new notification is available (triggered from NotificationTrigger)
  // generate the new notification and render it into the centre seamlessly
  NotificationCentre.prototype.refresh = function(initial) {
    // if refreshing already don't allow another
    if (this.isRefreshing) {
      return false;
    }

    this.isRefreshing = true;
    this.centre.$content.fadeOut(300, function() {
      this.centre.$body.css('height', '100%');
      this.centre.$content.empty();
      this.centre.$loading.show().appendTo(this.centre.$content);
      this.centre.$content.fadeIn(200);

      // if destroy(s) in progress wait until all complete
      if (this.destroyQueue.length || this.seenQueue.length) {
        var queuesInterval = setInterval(function() {
          if (!this.isOpen) {
            clearInterval(queuesInterval);
            return false;
          }

          if (!this.destroyQueue.length && !this.seenQueue.length) {
            clearInterval(queuesInterval);
            render.call(this);
          }
        }.bind(this), 2500);
        return false;
      }

      function render() {
        // generate notifications - request data from server
        this.createNotifications(function(notifications) {
          if (!this.isOpen) {
            return false;
          }

          if (!notifications || $.isArray(notifications) && !notifications.length) {
            this.centre.$loading.fadeOut(200, function() {
              this.isRefreshing = false;
              this.centre.$loading.remove();
              this.renderNoNotificationsFound();
            }.bind(this));
            return false;
          }

          // as .empty() was called all notification event handlers were cleared,
          // we need to reattach before we add new notifications to the stack.
          this.reAttachNotificationsEvents();

          // expose received notifications - keep existing data only add new notifications
          this.notifications = this.notifications.concat(this.findNewNotifications(notifications));

          // tell server unread notifications are now 'seen'
          this.postSeenNotifications();

          // fade out loading icon, remove then render notifications into DOM
          this.centre.$loading.fadeOut(200, function() {
            this.centre.$body.removeAttr('style');
            this.centre.$loading.remove();
            this.centre.$content.hide();
            this.renderNotifications();
            this.centre.$content.fadeIn(300);
            this.calcBody();
            this.$document.add(this.centre.$content)
              .off('scroll.arc-notification-centre')
              .on('scroll.arc-notification-centre', this.calcBody.bind(this));
            this.$window
              .off('resize.arc-notification-centre')
              .on('resize.arc-notification-centre', this.calcBody.bind(this));
            this.isRefreshing = false;
          }.bind(this));
        }.bind(this));
      }
      render.call(this);
      if (initial) {
        this.trigger.counter = 0;
        this.trigger.updateTrigger();
      }
    }.bind(this));
  };

  NotificationCentre.prototype.reAttachNotificationsEvents = function() {
    // add handleClick event to all notifications in this.notifications.
    $.each(this.notifications, function(i, notification) {
      notification.notification.$title.on('click', this.handleClick.bind(this));
      notification.notification.$details.on('click', this.handleClick.bind(this));
      notification.notification.$body.on('click', this.handleClick.bind(this));
      notification.notification.$close.on('click', this.handleDelete.bind(this));
    });
  };

  NotificationCentre.prototype.findNewNotifications = function(notificationsData) {
    var newNotifications = [],
        newNotificationFound = true;

    // loop through each data obj
    $.each(notificationsData, function(i, notificationData) {
      // loop through each existing notification instance
      $.each(this.notifications, function(j, notification) {
        // if current notification instance date matches data obj date skip this notification entirely
        if (notification.postId === notificationData.postId) {
          newNotificationFound = false;
          return false;
        }
      }.bind(this));

      if (newNotificationFound) {
        newNotifications.push(notificationData);
      }

      // reset var for next loop
      newNotificationFound = true;
    }.bind(this));

    return newNotifications;
  };

  // NOTE: Possible improvement later on - push notifications into stack when incoming notifications
  // NotificationCentre.prototype.renderIncomingNotifications = function(notifications) {
  //   // reversedNotifications = this.notifications.slice().reverse();
  //   var $title,
  //       nextNotification,
  //       prevNotification,
  //       currentNotification;
  //
  //   this.centre.$content.fadeOut(300, function() {
  //     $.each(notifications, function(i, notification) {
  //       $title = $('<h3/>').addClass('notification-centre-content-title').attr('data-date', notification.date).text(notification.date);
  //       nextNotification = this.notifications[notification.index + 1];
  //       prevNotification = this.notifications[notification.index - 1];
  //       currentNotification = this.notifications[notification.index],
  //       injectedTitle = false;
  //
  //       // if first notification or prev notification is not same date as current notification inject date title
  //       if (!prevNotification) {
  //         injectedTitle = true;
  //         $title.prependTo(this.centre.$content);
  //       } else if (prevNotification.date !== notification.date) {
  //         injectedTitle = true;
  //         $title.insertAfter(prevNotification.notification.$notification);
  //       }
  //
  //       // insert notification after date title if injected or previous notification
  //       currentNotification.notification.$notification.insertAfter(injectedTitle ? $title : prevNotification.notification.$notification);
  //     }.bind(this));
  //
  //     if (this.centre.$filter && this.centre.$filter.find('input')[0].checked) {
  //       this.filter(true);
  //     }
  //
  //     this.centre.$content.fadeIn(300);
  //   }.bind(this));
  // };

  NotificationCentre.prototype.postSeenNotifications = function() {
    // NOTE: We intentionally do not mark notifications as read in the UI as the UI
    // "red dot markers" stay as unread until the centre is closed, upon re-opening
    // the server would have marked it as "SEEN" for us.
    var unreadNotificationsIds = [];
    this.seenQueue = this.getUnreadNotifications();

    // push ids to array ready for POST request
    $.each(this.seenQueue, function(i, unreadNotification) {
      unreadNotificationsIds.push(unreadNotification.postId);
    });

    // POST all unread notification ids to be marked by server as "SEEN"
    $.ajax({
      url: arc.defaultContextPath + '/service/notification/setUserNotification',
      type: 'POST',
      cache: false,
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({'notificationIds': unreadNotificationsIds})
    })
    .always(function(data, status, xhr, error) {
      this.seenQueue = [];
      removeNotificationCountCache(unreadNotificationsIds);
    }.bind(this));
  };

  NotificationCentre.prototype.getUnreadNotifications = function() {
    var unread = [];

    $.each(this.notifications, function(i, notification) {
      if (!notification.read) {
        unread.push(notification);
      }
    });

    return unread;
  };

  NotificationCentre.prototype.createFilter = function() {
    this.isFilterable = true;
    var $checkbox = $('<input/>').attr('type', 'checkbox').on('click', function(e) {
      this.toggleFilter(e);
    }.bind(this));

    return $('<div/>').addClass('notification-centre-filter').hide().append(
      $('<label/>').text(arc.i18n.get('arc.notification-centre.filter.title')),
      $('<label/>')
        .addClass('switch')
        .append(
          $checkbox,
          $('<span/>')
        )
      );
  };

  NotificationCentre.prototype.renderFilter = function() {
    this.isFilterable = true;
    this.centre.$filter = this.createFilter();
    this.centre.$filter.appendTo(this.centre.$header);
    this.centre.$filter.fadeIn(300);
    return this.centre.$filter;
  };

  NotificationCentre.prototype.toggleFilter = function(e) {
    if (this.isRefreshing || this.isFiltering) {
      e.preventDefault();
      return;
    }

    if (this.isFiltered) {
      this.unfilter();
    } else {
      this.filter();
    }
  };

  NotificationCentre.prototype.unfilter = function() {
    if (this.isFiltering) {
      return false;
    }

    this.centre.$filter.find('span').removeClass('input-checked');
    this.isFiltering = true;
    this.isFiltered = false;
    this.centre.$content.fadeOut(200, function() {
      var $items = this.centre.$content.children();

      $.each(this.notifications, function(i, notification) {
        if (!notification.filtered) {
          notification.show(true);
        }
      }.bind(this));

      this.handleNoNotificationsFound(true);
      this.centre.$content.fadeIn(300);
      this.isFiltering = false;
    }.bind(this));
  };

  NotificationCentre.prototype.filter = function(refreshing) {
    if (this.isFiltering) {
      return false;
    }

    this.centre.$filter.find('span').addClass('input-checked');
    this.isFiltering = true;
    this.isFiltered = true;
    this.centre.$content.fadeOut(200, function() {
      var $notification;
      $.each(this.notifications, function(i, notification) {
        $notification = notification.notification.$notification;
        if (!notification.filtered) {
          notification.hide(true);
        }
      }.bind(this));

      this.handleNoNotificationsFound(true);

      if (!refreshing) {
        this.centre.$content.fadeIn(300);
      }

      this.isFiltering = false;
    }.bind(this));
  };

  NotificationCentre.prototype.handleNoNotificationsFound = function(instant) {
    if (this.isRefreshing) {
      return false;
    }

    if (this.hasNoNotifications()) {
      this.removeNoNotificationsFound(true);
      this.renderNoNotificationsFound(instant);
      return true;
    }

    this.removeNoNotificationsFound(instant);
    return false;
  };

  NotificationCentre.prototype.renderNoNotificationsFound = function(instant) {
    if (this.isFiltered) {
      this.centre.$content.append(this.centre.$noNotificationsFiltered.hide());
      this.centre.$noNotificationsFiltered.fadeIn(instant ? 0 : 300);
      return;
    }

    this.centre.$content.append(this.centre.$noNotifications.hide());
    this.centre.$noNotifications.fadeIn(instant ? 0 : 300);
  };

  NotificationCentre.prototype.removeNoNotificationsFound = function(instant) {
    this.centre.$content.children('div.notification-centre-no-notifications').fadeOut(instant ? 0 : 300, function() {
      $(this).remove();
    });
  };

  NotificationCentre.prototype.hasNoNotifications = function() {
    var hiddenNotificationsCount = 0;

    if (!this.notifications.length) {
      return true;
    }

    $.each(this.notifications, function(i, notification) {
      if (!notification.isVisible) {
        hiddenNotificationsCount++;
      }
    });

    if (hiddenNotificationsCount === this.notifications.length) {
      return true;
    }

    return false;
  };

  NotificationCentre.prototype.getPreviousVisibleNotification = function(i) {
    if (!this.notifications[i]) {
      return false;
    }

    if (this.notifications[i].isVisible) {
      return this.notifications[i];
    }

    return this.getPreviousVisibleNotification(i - 1);
  };

  NotificationCentre.prototype.getNextVisibleNotification = function(i) {
    if (!this.notifications[i]) {
      return false;
    }

    if (this.notifications[i].isVisible) {
      return this.notifications[i];
    }

    return this.getNextVisibleNotification(i + 1);
  };

  NotificationCentre.prototype.isNotificationLastVisibleInDate = function(i) {
    var nextVisibleNotification = this.getNextVisibleNotification(i + 1),
        previousVisibleNotification = this.getPreviousVisibleNotification(i - 1),
        currentNotification = this.notifications[i];

    // Conditional to check if this notification is the last visible in a set of the same date
    //
    // if next notification doesn't exist or next notification exists and isn't same date as this one and
    // either this is first notification or previous visible notification (recursive) has different date at this one
    if ((nextVisibleNotification === false || nextVisibleNotification && currentNotification.date !== nextVisibleNotification.date) &&
        (previousVisibleNotification === false || previousVisibleNotification && currentNotification.date !== previousVisibleNotification.date)) {
      return currentNotification;
    }

    return false;
  };

  NotificationCentre.prototype.resetIndexes = function() {
    $.each(this.notifications, function(i, notification) {
      notification.index = i;
    });
  };

  function NotificationTrigger(notificationCentre) {
    this.notificationCentre = notificationCentre;
    this.isRequesting = false;
    this.initial = true;
    this.counter = 0;
    this.trigger = this.renderTrigger();
    this.poll();
  }

  // inject trigger into black toolbar
  NotificationTrigger.prototype.renderTrigger = function() {
    var $toolbar = $('.toolbar').not('#alternative-language-toolbar'),
        $target = $toolbar.children('ul').eq(1),
        $mobileTarget = $toolbar.children('.toolbar-responsive'),
        // Trigger UI elements
        $trigger = $('<a/>').addClass('notification-centre-trigger').on('click', this.notificationCentre.toggle.bind(this.notificationCentre)),
        $bell = $('<img/>', {src: arc.defaultContextPath + '/images/arc/notification-bell.png'}).addClass('notification-centre-trigger-bell').appendTo($trigger),
        $text = $('<span/>').addClass('notification-centre-trigger-text').text(arc.i18n.get('arc.notification-centre.title')).appendTo($trigger),
        $counter = $('<span/>').addClass('notification-centre-trigger-counter notification-centre-trigger-counter-none').text(0).appendTo($trigger),
        // Mobile Trigger UI elements
        $mobileTrigger = $('<span/>').addClass('notification-centre-trigger notification-centre-trigger-mobile').on('click', this.notificationCentre.toggle.bind(this.notificationCentre)),
        $mobileBell = $('<img/>', {src: arc.defaultContextPath + '/images/arc/notification-bell.png'}).addClass('notification-centre-trigger-bell').appendTo($mobileTrigger),
        $mobileCounter = $('<span/>').addClass('notification-centre-trigger-counter notification-centre-trigger-counter-none').text(0).appendTo($mobileTrigger),
        $counters = $counter.add($mobileCounter),
        $targets = $target.add($mobileTarget);

    $target = $('<li/>').appendTo($('<ul></ul>').insertAfter($toolbar.children('ul').eq(1)));
    $targets = $target.add($mobileTarget);

    if ($.browser.msie && $.browser.versionNumber === 8) {
      $trigger.appendTo($target);
      $mobileTrigger.appendTo($mobileTarget);
    } else {
      $bell.add($mobileBell).on('load', function() {
        $trigger.appendTo($target);
        $mobileTrigger.appendTo($mobileTarget);
      }.bind(this));
    }


    return {
      $counters: $counters,
      $bells: $bell.add($mobileBell),
      $counter: $counter,
      $trigger: $trigger,
      $bell: $bell,
      $text: $text,
      $mobileTrigger: $mobileTrigger,
      $mobileBell: $mobileBell,
      $mobileCounter: $mobileCounter
    };
  };

  NotificationTrigger.prototype.poll = function() {
    var notificationTrigger = this,
        pollInterval,
        pollCallback = function() {
          var runCount = function() {
            notificationTrigger.count.call(notificationTrigger);
          };

          runCount();
          return runCount;
        }.bind(this);

    // pollInterval = setInterval((pollCallback.bind(this)()), 10000);
    // Only run on page load instead of timer set in above comment
    pollCallback();
  };


  NotificationTrigger.prototype.count = function() {
    // if request in progress defer till complete (reinitialise interval)
    if (this.isRequesting || this.notificationCentre.isRefreshing || this.notificationCentre.destroyQueue.length) {
      return;
    }

    var notificationCount = getNotificationCountCache();
    if (notificationCount && new Date().getTime() < notificationCount.expiryTime) {
        //restore data from session
        this.counter = notificationCount.count;
        this.updateTrigger();
        return;
    }

    this.isRequesting = true;

    $.ajax({
      url: arc.defaultContextPath + '/service/notification/getNotificationCount',
      type: 'GET',
      cache: false
    })
    .done(function(data) {
      this.isRequesting = false;
      // don't update the counter if given no data or data doesn't validate to number
      if (data && typeof data.count === 'number' && typeof data.cachePeriod === 'number') {
        //store data in session to decrease calls number
        setNotificationCountCache(data.count, (new Date().getTime() + data.cachePeriod * 1000).toString());
        this.counter = data.count;
        this.updateTrigger();
      }
    }.bind(this));
  };

  // Using either a predefined count argument or default to getUnreadNotifications,
  // set the trigger counter to the current unread notifications total
  // (animates bell if count is greater than current count)
  NotificationTrigger.prototype.updateTrigger = function() {
    this.updateBell();
    this.updateCounter(function(hasNewNotifications) {
      if (hasNewNotifications) {
        this.notificationCentre.refresh.call(this.notificationCentre, false);
      }
    }.bind(this));
    this.initial = false;
  };

  NotificationTrigger.prototype.updateBell = function() {
    var currentCounter = this.trigger.$counter.text().indexOf('+') > -1 ? 100 : parseInt(this.trigger.$counter.text()) || 0;

    // if open update bell only if count is truthy
    if (this.notificationCentre.isOpen && !this.notificationCentre.destroyQueue.length && this.counter !== currentCounter && this.counter !== 0 ||
        !this.notificationCentre.isOpen && this.counter !== currentCounter && this.counter !== 0) {
      this.animateBell();
      return;
    }
  };

  NotificationTrigger.prototype.updateCounter = function(done) {
    // if open update counter only if count is truthy
    //this will never run as we are not polling
    if (this.notificationCentre.isOpen && !this.notificationCentre.destroyQueue.length && !this.notificationCentre.isRefreshing) {
      if (typeof done === 'function') {
        done(this.setCounter(true));
        return;
      }
      this.setCounter(true);
      return;
    }
    this.setCounter();

    if (typeof done === 'function') {
      done(false);
    }
  };

  NotificationTrigger.prototype.setCounter = function(addToExisting) {
    var refresh = false;
    this.trigger.$counters.removeClass('notification-centre-trigger-counter-none notification-centre-trigger-counter-overflow');

    if (addToExisting) {
      refresh = this.setOpenCounter();
    } else {
      this.setClosedCounter();
    }
    if (this.counter === 0) {
      this.trigger.$counters.addClass('notification-centre-trigger-counter-none');
    }

    return refresh;
  };

  // this is never triggered currently, I know this because it was incorrectly named as NotificationCentre
  // maybe used if polling was in place?
  NotificationTrigger.prototype.setOpenCounter = function() {
    var currentCounter = this.trigger.$counter.text().indexOf('+') > -1 ? 100 : parseInt(this.trigger.$counter.text()) || 0,
        refresh = this.counter !== 0;

    if (refresh) {
      this.animateCounter();
    }

    // unread notifications stay unread in centre when open until closed,
    // so add onto existing unread (even though they have been seen)
    this.counter = this.counter + currentCounter;

    if (this.counter > 99) {
      this.trigger.$counters.addClass('notification-centre-trigger-counter-overflow').text('99+');
      return refresh;
    }

    this.trigger.$counters.text(this.counter);
    return refresh;
  };

  NotificationTrigger.prototype.setClosedCounter = function() {
    var currentCounter = this.trigger.$counter.text().indexOf('+') > -1 ? 100 : parseInt(this.trigger.$counter.text()) || 0;
    // if predefined count not given, set to getUnreadNotifications returned array length
    if (this.notificationCentre.isRefreshing || this.notificationCentre.destroyQueue.length) {
      this.counter = this.notificationCentre.getUnreadNotifications().length;
    }

    if (this.counter !== currentCounter || this.initial) {
      this.animateCounter();
    }

    if (this.counter > 99) {
      this.trigger.$counters.addClass('notification-centre-trigger-counter-overflow').text('99+');
      return;
    }

    this.trigger.$counters.text(this.counter);
  };

  NotificationTrigger.prototype.animateCounter = function() {
    this.trigger.$counters.removeClass('notification-centre-trigger-counter-visible');

    setTimeout(function() {
      this.trigger.$counters.addClass('notification-centre-trigger-counter-visible');
    }.bind(this), 250);
  };

  NotificationTrigger.prototype.animateBell = function() {
    setTimeout(function() {
      this.trigger.$bells.addClass('notification-centre-trigger-bell-active');

      setTimeout(function() {
        this.trigger.$bells.removeClass('notification-centre-trigger-bell-active');
      }.bind(this), 250);
    }.bind(this), 0);
  };

  function Notification(data) {
    this.data = data || {};
    this.index = this.data.index;
    this.disabled = false;
    this.notificationCentre = this.data.notificationCentre;
    this.type = this.data.notificationType || null;
    this.read = this.data.notificationState === 'SEEN' ? true : false;
    this.title = this.renderTitle();
    this.description = this.renderDescription();
    this.timestamp = this.data.created || null;
    this.date = this.data.created ? this.renderDate(this.data.created) : null;
    this.time = this.data.created ? this.renderTime(this.data.created) : null;
    this.filtered = this.data.vehicleSpecific || false;
    this.postId = this.data.notificationId;
    this.url = this.renderLink();
    this.workflowId = this.data.workflowId;
    this.workflowResumable = this.data.hasOwnProperty('workflowResumable') && this.data.workflowResumable;
    this.isVisible = true;
    this.notification = this.renderNotification();
    this.gcmReportId = this.data.gcmReportId;
    this.$dialog = null;
    this.$dialogBody = null;
    this.gcmReportType = this.data.gcmReportType || null;

    this.notification.$notification.data('instance', this);
  }

  Notification.prototype.show = function(instant) {
    var $show = this.notification.$notification;

    if (this.disabled) {
      return false;
    }

    this.isVisible = true;

    if (this.notificationCentre.isNotificationLastVisibleInDate(this.index)) {
      $show = $show.add(this.notificationCentre.centre.$content.children().filter('[data-date="' + this.date + '"]'));
    }

    $show.addClass('notification-shown').removeClass('notification-hidden');

    setTimeout(function() {
      $show.removeClass('notification-shown');
    }.bind(this), instant ? 0 : 300);
  };

  Notification.prototype.hide = function(instant, destroy) {
    var $hide = this.notification.$notification,
        includeTitle = false;

    if (this.disabled) {
      return false;
    }

    this.isVisible = false;

    if (this.notificationCentre.isNotificationLastVisibleInDate(this.index)) {
      $hide = $hide.add(this.notificationCentre.centre.$content.children().filter('[data-date="' + this.date + '"]'));
      includeTitle = true;
    }

    $hide.addClass('notification-animate-hidden');
    // $hide.slideUp(300);

    setTimeout(function() {
      $hide.addClass('notification-hidden').removeClass('notification-animate-hidden');

      setTimeout(function() {
        if (destroy) {
          this.destroy(includeTitle);
        }
      }.bind(this), 0);
    }.bind(this), instant ? 0 : 300);
  };

  Notification.prototype.destroy = function(includeTitle) {
    this.notificationCentre.destroyQueue.unshift(this);
    this.notification.$notification.remove();

    if (includeTitle) {
      this.notificationCentre.centre.$content.children().filter('div.notification-centre-content-title-wrapper[data-date="' + this.date + '"]').remove();
    }

    this.notificationCentre.notifications.splice(this.index, 1);
    this.notificationCentre.resetIndexes();
    this.notificationCentre.trigger.updateTrigger();
    this.notificationCentre.handleNoNotificationsFound();

    var notificationId = [this.postId];
    $.ajax({
      url: arc.defaultContextPath + '/service/notification/changeUserNotificationStateToRead',
      type: 'POST',
      cache: false,
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({'notificationIds': notificationId})
    })
    .always(function() {
      this.notificationCentre.destroyQueue.pop();
      removeNotificationCountCache(notificationId);
    }.bind(this));
  };

  Notification.prototype.handleDisabled = function() {
    if (this.disabled) {
      this.notification.$notification.addClass('notification-disabled');
      return true;
    }

    this.notification.$notification.removeClass('notification-disabled');
    return false;
  };

  Notification.prototype.renderTitle = function() {
    var type = this.type;
    if (type === 'DOCUMENT_PUBLISHED') {
      return arc.i18n.get('arc.notification-center.notification.new-content');
    }
    else if (type === 'NOTICE_PUBLISHED') {
      return arc.i18n.get('arc.notification-center.notification.new-content');
    }
    else if (type.indexOf("GCM") === 0) {
      return arc.i18n.get('arc.notification-centre.gcm.title.key').replace("{0}", this.data.gcmReportType);
    }
    else {
        if (this.data.titleKey) {
          return this.data.titleKey;
        }
        return '';
      }
  };

  Notification.prototype.renderDescription = function() {
      var type = this.type;
      if (type === 'DOCUMENT_PUBLISHED') {
        return this.data.documentTitle;
      }
      else if (type === 'NOTICE_PUBLISHED') {
        return this.data.noticeTitle;
      }
      else if (type.indexOf("GCM") === 0) {
        return this.data.notificationContent;
      }
      else {
        if (this.data.bodyKey) {
          return this.data.bodyKey;
        }
        return '';
    }
  };

  Notification.prototype.renderDate = function(date) {
    var today = new Date(),
        yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

    // render date given from server into javascript date
    date = new Date(date);

    // is not a valid date
    if (isNaN(date)) {
      return null;
    }

    // reset all dates hours, min, sec, millisec to 0 so comparison works
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    // if notification date is todays date
    if (date.getTime() === today.getTime()) {
      return arc.i18n.get('arc.notification-centre.date.day.today');
    }

    // if notification date is yesterdays date
    if (date.getTime() === yesterday.getTime()) {
      return arc.i18n.get('arc.notification-centre.date.day.yesterday');
    }

    // return formatted date (i.e. Saturday 14th April)
    return this.renderDay(date.getDay() === 0 ? 6 : date.getDay() - 1) + ' ' + date.getDate() + ' ' + this.renderMonth(date.getMonth());
  };

  Notification.prototype.renderDay = function(day) {
    var days = [
      arc.i18n.get('arc.notification-centre.date.day.monday'),
      arc.i18n.get('arc.notification-centre.date.day.tuesday'),
      arc.i18n.get('arc.notification-centre.date.day.wednesday'),
      arc.i18n.get('arc.notification-centre.date.day.thursday'),
      arc.i18n.get('arc.notification-centre.date.day.friday'),
      arc.i18n.get('arc.notification-centre.date.day.saturday'),
      arc.i18n.get('arc.notification-centre.date.day.sunday')
    ];

    return days[day];
  };

  Notification.prototype.renderMonth = function(month) {
    var months = [
      arc.i18n.get('arc.notification-centre.date.month.january'),
      arc.i18n.get('arc.notification-centre.date.month.february'),
      arc.i18n.get('arc.notification-centre.date.month.march'),
      arc.i18n.get('arc.notification-centre.date.month.april'),
      arc.i18n.get('arc.notification-centre.date.month.may'),
      arc.i18n.get('arc.notification-centre.date.month.june'),
      arc.i18n.get('arc.notification-centre.date.month.july'),
      arc.i18n.get('arc.notification-centre.date.month.august'),
      arc.i18n.get('arc.notification-centre.date.month.september'),
      arc.i18n.get('arc.notification-centre.date.month.october'),
      arc.i18n.get('arc.notification-centre.date.month.november'),
      arc.i18n.get('arc.notification-centre.date.month.december'),
    ];

    return months[month];
  };

  Notification.prototype.renderTime = function(date) {
    date = new Date(date);

    // is not a valid date
    if (isNaN(date)) {
      return '';
    }

    return (date.getHours().toString().length === 1 ? '0' + date.getHours().toString() : date.getHours().toString()) +
            ':' +
            (date.getMinutes().toString().length === 1 ? '0' + date.getMinutes().toString() : date.getMinutes().toString());
  };

  Notification.prototype.renderNotification = function() {
    var $notification = $('<div/>').addClass('notification'),
        $header = $('<div/>').addClass('notification-header').appendTo($notification),
        $title = $('<h3/>').addClass('notification-title').text(this.title).appendTo($header),
        $details = $('<div/>').addClass('notification-details').appendTo($header),
        $read = !this.read ? $notification.addClass('notification-unread'): null,
        $GCM = this.type.indexOf('GCM') !== -1 ? $header.prepend($('<i class="fa fa-folder" aria-hidden="true"></i>')) : null,
        $close = $('<i/>').addClass("fa fa-times notification-single-close").appendTo($header),
        $filtered = this.filtered ? $header.prepend($('<i class="fa fa-car" aria-hidden="true"></i>')) : null,
        $time = $('<span/>').text(this.time).appendTo($details),
        $body = $('<div/>').addClass('notification-body').html(this.description).appendTo($notification);

        $title.on('click', this.handleClick.bind(this));
        $details.on('click', this.handleClick.bind(this));
        $close.on('click', this.handleDelete.bind(this));
        $body.on('click', this.handleClick.bind(this));

    return {
      $notification: $notification,
      $header: $header,
      $title: $title,
      $details: $details,
      $filtered: $filtered,
      $time: $time,
      $close : $close,
      $body: $body
    };
  };
  function getFREDStatus(status) {
      switch(status) {
          case 'GCM_CLOSURE':
              return arc.i18n.get('arc.notification-centre.gcm.fred.dialog.status.closed');
          case 'GCM_UPDATE':
              return arc.i18n.get('arc.notification-centre.gcm.fred.dialog.status.updated');
          case 'GCM_FRED_APPROVAL':
              return arc.i18n.get('arc.notification-centre.gcm.fred.dialog.status.approved');
          case 'GCM_FRED_REJECTION':
              return arc.i18n.get('arc.notification-centre.gcm.fred.dialog.status.rejected');
          case 'GCM_RESPONSE':
              return arc.i18n.get('arc.notification-centre.gcm.fred.dialog.status.new.response');
          default:
              return '';
      }
  }

  Notification.prototype.renderDialogBody = function() {
      var thiz = this;
      var url = $i.contextPath + '/technical/assistance/reportStateDetails?reportId=' + this.gcmReportId;
      this.$dialogBody = $('<div id="notificationDialog"><p style="text-align: center"><i class="fa fa-spinner fa-spin" style="font-size:40px" aria-hidden="true"></i></p></div>');

      $.ajax({
          url: encodeURI(url),
          type: 'GET',
          dataType: 'json'
      }).then(function(data){
          if (data === {} || data.status === "error") {
              $('#notificationDialog').html('<div>'+arc.i18n.get('arc.notification-centre.gcm.fred.dialog.content.error')+'</div>');
              return false;
          }
          $(thiz.$dialog.$el).find('.disabled').removeClass('disabled');
          var notificationType = getFREDStatus(thiz.type);
          var para1 = arc.i18n.get('arc.notification-centre.gcm.fred.dialog.content.para1')
              .replace('{0}', '<b>' + thiz.gcmReportId + '</b>')
              .replace('{1}', '<span style="font-size:20px">' + data.model + '</span>')
              .replace('{2}', '<span>' + data.vehicle_year + '</span>')
              .replace('{3}', '<span style="font-size:20px">' + data.vin + '</span>')
              .replace('{4}', '<br/><span style="font-size:35px;padding-top:15px;display:inline-block;">' + notificationType + '</span>')

          var para2 = arc.i18n.get('arc.notification-centre.gcm.fred.dialog.content.para2');
          $('#notificationDialog').html('<div><p>'+para1+'</p><p>'+para2+'</p></div>');

      }, function (){
          $('#notificationDialog').html('<div>'+arc.i18n.get('arc.notification-centre.gcm.fred.dialog.content.error')+'</div>');
      })

      return thiz.$dialogBody;
  }

  Notification.prototype.renderDialog = function() {
      var thiz = this;
      var workflowUrl = $i.contextPath + '/workflow/resume?processInstanceId=' + this.workflowId;
      if (thiz.$dialog != null) {
        thiz.$dialog.destroy();
      }
      var dialogOptions = {
          title: arc.i18n.get('arc.notification-centre.gcm.fred.request.update'),
          width: '40%',
          body: thiz.renderDialogBody(),
          close: function () {
              thiz.$dialog.overlay.remove();
              thiz.$dialog.box.remove();
          }
      };

      var buttons = {
          'arc.notification-centre.gcm.view.report': {
              classes: 'btn-primary disabled',
                  callback: function () {
                  window.open(thiz.url, '_blank');
                  thiz.$dialog.destroy();
              }
          }
      };

      if(thiz.workflowResumable == true) {
          buttons['arc.notification-centre.gcm.resume.workflow'] = {
              classes: 'btn-primary disabled',
                  callback: function () {
                  window.open(workflowUrl, '_parent');
                  thiz.$dialog.destroy();
              }
          }
      }
      dialogOptions.buttonKeys = buttons;
      thiz.$dialog = $i.dialog(dialogOptions);
  }

  Notification.prototype.renderLink = function() {
    switch (this.type) {
      case 'DOCUMENT_PUBLISHED': {
        return arc.defaultContextPath + '/content/document/view?id=' + this.data.documentId;
      }
      case 'NOTICE_PUBLISHED': {
        return arc.defaultContextPath + '/notice/list?page=0#' + this.data.noticeId;
      }
      default:
          if (this.type.indexOf("GCM") === 0) {
              return arc.defaultContextPath + '/technicalsupport?id=' + this.data.gcmReportId + '&accountId=' + this.data.accountId + '&accountName=' + this.data.accountName;
          }
          return null;
    }
  };

  Notification.prototype.handleDelete = function () {
      this.hide(false, true);
  };

  Notification.prototype.handleClick = function() {
    var newWindow;

    if (this.workflowId != null && this.gcmReportType === 'FRED') {
        return this.renderDialog();
    }

    if (this.url) {
      newWindow = window.open('', '_blank');
    }

    if (!this.url) {
      return false;
    }

    // wait for notification hide animation to complete
    setTimeout(function() {
       newWindow.location = this.url;
    }.bind(this), 300);
  };

  function notificationCentre(options) {
    return new NotificationCentre(options);
  }

  $.extend(true, arc, {
    notificationCentre: notificationCentre
  });

  $.fn.notificationCentre = function(options) {
    return new NotificationCentre(options);
  };

})(arc, jQuery);
